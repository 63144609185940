import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Brands from "../components/home/Brands"
import Breathing from "../components/home/Breathing"
import Hero from "../components/home/Hero"
import NewHowItWorks from "../components/home/NewHowItWorks"
import IntroducingPnoe from "../components/home/IntroducingPnoe"
import PnoeAi from "../components/home/PnoeAi"
import Slider from "../components/home/Slider"
import Spartan from "../components/home/Spartan"
import Stats from "../components/home/Stats"
import Testimonials from "../components/home/Testimonials"
import Divider from "../components/common/Divider"
import useWindowSize from "../hooks/useWindowSize"
import { DEVICES } from "../config"
import TrackingPlatform from "../components/home/TrackingPlatform"
import GrowYourBusiness from "../components/home/GrowYourBusiness"

function Home({ data }) {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="Home" />
      <Divider.Light />
      <main>
        {width < DEVICES.desktop ? <Hero.Mobile /> : <Hero />}
        <Divider.Light />
        <IntroducingPnoe fluid={data.intro.childImageSharp.fluid} />
        <Brands />
        <Divider.Light />
        <NewHowItWorks />
        <Divider.Light />
        <PnoeAi />
        <Divider.Light />
        <GrowYourBusiness />
        <Divider.Light />
        <Testimonials />
        <Slider />
        {width < DEVICES.desktop ? (
          <TrackingPlatform.Mobile />
        ) : (
          <TrackingPlatform />
        )}
        <Divider.Light />
        <Stats />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro: file(relativePath: { eq: "introducing-pnoe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1628, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Home
