import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../virtual-lab/Container"
import Button from "../common/Button"
import { Link } from "gatsby"

const DATA = {
  title: "The most advanced tracking platform",
  descriptions: [
    "Your clients' PNOĒ Precision app syncs in real-time with the PNOĒ monitoring platform. Through it, you can track essential health metrics, their nutrition, physical recovery, sleep, and activity and get a detailed analysis of every workout.",
  ],
  button: {
    name: "Learn more",
    to: "/virtual-lab",
  },
}

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)
export default function TrackingPlatform() {
  return (
    <Container bleed="both" py="10rem">
      <Container.Image align="left">
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/tracking-platform.png"
          alt="phone"
          width={500}
        />
      </Container.Image>
      <div>
        <Container.Title width="42rem">{DATA.title}</Container.Title>
        <Container.Description width="42rem">
          {DATA.descriptions[0]}
        </Container.Description>
        <CTA />
      </div>
    </Container>
  )
}

TrackingPlatform.Mobile = () => (
  <Container bleed="both">
    <Container.Title width="35rem">{DATA.title}</Container.Title>
    <StaticImage
      placeholder="blurred"
      src="../../assets/images/tracking-platform.png"
      alt="phone"
      width={337}
    />
    <Container.Description mt={32} width="35rem">
      {DATA.descriptions[0]}
    </Container.Description>
    <CTA />
  </Container>
)
