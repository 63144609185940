import React from "react"
import styled from "styled-components"
import { STEPS, DEVICES } from "../../config"
import Container from "../common/Container"
import ExperiencePnoe from "./ExperiencePnoe"

const Card = styled.div`
  padding-left: 40px;
  padding-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 22px;
  min-height: 350px;
  box-shadow: 5px 5px 10px 0 #ccc2c4, -5px -5px 10px 0 #ffffff;
  overflow: hidden;

  @media (max-width: ${DEVICES.mobile}px) {
    padding-left: 20px;
    padding-top: 20px;
  }
`
const Title = styled.h3`
  font-size: 22px;
  font-weight: 500;
  line-height: 1.17;
  margin: 0;
  margin-bottom: 9px;
  position: relative;
  z-index: 1;
  max-width: 200px;

  @media (max-width: ${DEVICES.mobile}px) {
    max-width: 100%;
    font-size: 18px;
    padding-right: 20px;
  }
`
const Description = styled.p`
  font-size: 14px;
  opacity: 0.7;
  line-height: 1.71;
  margin: 0;
  position: relative;
  z-index: 1;

  @media (max-width: ${DEVICES.mobile}px) {
    font-size: 12px;
    padding-right: 20px;
  }
`
const Step = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  color: #1979ff;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: #fffdfd;
  margin-bottom: 16px;

  @media (max-width: ${DEVICES.mobile}px) {
    font-size: 14px;
  }
`

const Image = styled.img`
  position: absolute;
  z-index: 0;
`

export function NewHowItWorks() {
  return (
    <Container
      light
      css={`
        padding: 48px 0;
      `}
    >
      <h2
        css={`
          font-size: 32px;
          font-weight: bold;
          line-height: 1.5;
          text-align: center;
          margin: 0;
          margin-bottom: 22px;
        `}
      >
        How it works
      </h2>
      <Container
        light
        compact
        css={`
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
          gap: 24px;

          @media (max-width: ${DEVICES.mobile}px) {
            grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
          }
        `}
      >
        <Card>
          <Step>Step 1</Step>
          <Title>{STEPS[0].title}</Title>
          <Description
            css={`
              max-width: 208px;

              @media (max-width: ${DEVICES.mobile}px) {
                max-width: 100%;
              }
            `}
          >
            {STEPS[0].description}
          </Description>
          <Image
            src={STEPS[0].image}
            alt={STEPS[0].title}
            css={`
              width: 326px;
              right: 0;
              bottom: 0;

              @media (max-width: ${DEVICES.mobile}px) {
                position: relative;
                top: 0;
                right: 0;
                margin-top: 20px;
                align-self: flex-end;
                width: 100%;
              }
            `}
          />
        </Card>
        <Card>
          <Step>Step 2</Step>
          <Title
            css={`
              @media (max-width: ${DEVICES.mobile}px) {
                max-width: 60%;
              }
            `}
          >
            {STEPS[1].title}
          </Title>
          <Description
            css={`
              max-width: 221px;

              @media (max-width: ${DEVICES.mobile}px) {
                max-width: 60%;
              }
            `}
          >
            {STEPS[1].description}
          </Description>
          <Image
            src={STEPS[1].image}
            alt={STEPS[1].title}
            css={`
              width: 222px;
              right: 0;
              top: 0;

              @media (max-width: ${DEVICES.mobile}px) {
                right: -50px;
              }
            `}
          />
        </Card>
        <Card>
          <Step>Step 3</Step>
          <Title>{STEPS[2].title}</Title>
          <Description
            css={`
              max-width: 221px;

              @media (max-width: ${DEVICES.mobile}px) {
                max-width: 100%;
              }
            `}
          >
            {STEPS[2].description}
          </Description>
          <Image
            src={STEPS[2].image}
            alt={STEPS[2].title}
            css={`
              width: 239px;
              right: 0;
              top: 54px;

              @media (max-width: ${DEVICES.mobile}px) {
                position: relative;
                top: 0;
                right: 0;
                margin-top: 20px;
                align-self: flex-end;
              }
            `}
          />
        </Card>
        <Card>
          <Step>Step 4</Step>
          <Title>{STEPS[3].title}</Title>
          <Description
            css={`
              max-width: 221px;

              @media (max-width: ${DEVICES.mobile}px) {
                max-width: 100%;
              }
            `}
          >
            {STEPS[3].description}
          </Description>
          <Image
            src={STEPS[3].image}
            alt={STEPS[3].title}
            css={`
              width: 213px;
              right: 0;
              bottom: 0;

              @media (max-width: ${DEVICES.mobile}px) {
                position: relative;
                top: 0;
                right: 0;
                margin-top: 20px;
                align-self: flex-end;
              }
            `}
          />
        </Card>
      </Container>
      <Container
        css={`
          display: flex;
          justify-content: center;
        `}
      >
        <ExperiencePnoe
          css={`
            margin-top: 48px;
          `}
        />
      </Container>
    </Container>
  )
}

export default NewHowItWorks
