import React from "react"
import Lottie from "react-lottie-player"
import hero from "../../assets/lottie/hero.json"
import Container from "../virtual-lab/Container"
import CustomSwiper from "../common/CustomSwiper"
import { SwiperSlide } from "swiper/react"
import { DEVICES } from "../../config"
import { StaticImage } from "gatsby-plugin-image"

const Title = props => (
  <Container.Title width="42rem" {...props}>
    Grow your virtual practice with the first clinical-grade home testing
    service.
  </Container.Title>
)

const Description = props => (
  <Container.Description width="36rem" {...props}>
    The PNOĒ home breath test scans your clients' metabolism, heart, and lungs
    with clinical precision and helps you personalize their nutrition, training,
    and recovery like never before.
  </Container.Description>
)

export default function Hero() {
  return (
    <Container bleed="right">
      <Container.Absolute
        left
        css={`
          display: flex;
        `}
      >
        <div>
          <Title />
          <Description />
        </div>
        <Lottie
          play={true}
          animationData={hero}
          css={`
            height: 280px;
            width: 280px;
            margin-left: auto;

            @media (max-width: ${DEVICES.mobile}px) {
              height: 230px;
              width: 230px;
            }
          `}
        />
      </Container.Absolute>
      <div
        css={`
          max-width: 83rem;
          margin-left: auto;
        `}
      >
        <CustomSwiper
          allowTouchMove={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect="fade"
          id="hero-slider"
          loop
          slidesPerView={1}
          css={`
            margin-left: auto;
            .hero-slider-next,
            .hero-slider-prev {
              display: none;
            }
          `}
        >
          <SwiperSlide>
            <StaticImage src="../../assets/images/hero-1.png" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage src="../../assets/images/hero-2.png" />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage src="../../assets/images/hero-3.png" />
          </SwiperSlide>
        </CustomSwiper>
      </div>
    </Container>
  )
}

Hero.Mobile = () => (
  <Container
    css={`
      padding-bottom: 0;

      .gatsby-image-wrapper {
        margin: 0;
      }
    `}
  >
    <Title width="32rem" />
    <div
      css={`
        position: relative;
        width: 100vw;
      `}
    >
      <CustomSwiper
        allowTouchMove={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        effect="fade"
        id="hero-slider"
        loop
        slidesPerView={1}
        css={`
          margin-left: auto;
          .hero-slider-next,
          .hero-slider-prev {
            display: none;
          }
        `}
      >
        <SwiperSlide>
          <StaticImage src="../../assets/images/mobile-hero-1.png" />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage src="../../assets/images/mobile-hero-2.png" />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage src="../../assets/images/mobile-hero-3.png" />
        </SwiperSlide>
      </CustomSwiper>
      <div
        css={`
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 11;
        `}
      >
        <Lottie
          play={true}
          animationData={hero}
          css={`
            height: 280px;
            width: 280px;
            margin-left: auto;

            @media (max-width: ${DEVICES.mobile}px) {
              height: 200px;
              width: 200px;
            }
          `}
        />
      </div>
    </div>
    <Description width="35rem" mt={32} />
  </Container>
)
