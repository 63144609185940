import React from "react"
import heroSpartan from "../../assets/images/hero-spartan.jpg"
import heroSpartanMobile from "../../assets/images/hero-spartan-mobile.jpg"
import { DEVICES } from "../../config"
import useWindowSize from "../../hooks/useWindowSize"
import Button from "../common/Button"
import Container from "../common/Container"

function Spartan() {
  const { width } = useWindowSize()
  return width >= DEVICES.desktop ? <Desktop /> : <Tablet />
}

function Tablet() {
  return (
    <Container
      light
      css={`
        position: relative;
        background: url(${heroSpartanMobile});
        background-size: cover;
        z-index: 0;
      `}
    >
      <div
        css={`
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          background: #ebeaea;
          opacity: 0.6;
        `}
      ></div>
      {/* <img
        css={`
          width: 100%;
          height: auto;
          position: absolute;
          object-size: cover;
        `}
        src={heroSpartan}
        alt="Spartan blog"
      /> */}
      <div
        css={`
          display: flex;
          text-align: center;
          flex-direction: column;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          line-height: 1.5;
          padding: 48px;
          position: relative;
          z-index: 2;

          ${Button} {
            margin-top: 24px;
          }
        `}
      >
        Read more on <br /> Spartan Blog
        <Button>Read on</Button>
      </div>
    </Container>
  )
}

function Desktop() {
  return (
    <Container
      light
      css={`
        position: relative;
        min-height: 378px;
      `}
    >
      <img
        css={`
          position: absolute;
          height: 100%;
          right: 0;
          top: 0;
        `}
        src={heroSpartan}
        alt="Spartan blog"
      />
      <div
        css={`
          position: absolute;
          background: linear-gradient(
            to left,
            rgba(235, 234, 234, 0),
            #ebeaea 50%
          );
          top: 0;
          left: 0;
          max-width: 969px;
          width: 100%;
          height: 100%;
          transition: background 250ms ease;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <div
          css={`
            font-size: 32px;
            font-weight: bold;
            line-height: 1.5;
            margin-left: calc(100vw / 2 - 1024px / 2);
            max-width: 320px;
            margin-bottom: 24px;
          `}
        >
          Read more on Spartan Blog
          <Button>Read on</Button>
        </div>
      </div>
    </Container>
  )
}

export default Spartan
