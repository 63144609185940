import React from "react"
import Container from "../virtual-lab/Container"
import IconArrow from "../../assets/images/icon-arrow-up.inline.svg"
import { P } from "../common/Typography"
import { MEDIA_QUERIES } from "../../config"
import CustomSwiper from "../common/CustomSwiper"
import { SwiperSlide } from "swiper/react"
import Button from "../common/Button"
import { Link } from "gatsby"

const STATS = [
  {
    title: "$200",
    description: "5% increase in daily metabolic rate",
  },
  {
    title: "4x",
    description: "client retention thanks to personalized programming",
  },
  {
    title: "20%",
    description: "more clients thanks to better outcomes",
  },
]

const CTA = props => (
  <Button as={Link} to="/why-pnoe" {...props}>
    Learn more
  </Button>
)

export default function GrowYourBusiness() {
  return (
    <Container bleed="both" py="5rem">
      <div
        css={`
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}
      >
        <Container.Title width="37rem">Grow your business</Container.Title>
        <Container.Description
          width="88rem"
          css={`
            font-size: 20px;
          `}
          weight="normal"
          mb={64}
        >
          3000+ health professionals have grown their business thanks to the
          personalization the PNOĒ test enables and the engagement PNOĒ
          Precision brings. Heres how:
        </Container.Description>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;

            ${MEDIA_QUERIES.belowDesktop} {
              display: none;
            }
          `}
        >
          {STATS.map(stat => (
            <Stat key={stat.title} {...stat} />
          ))}
        </div>
        <div
          css={`
            display: none;
            width: 90vw;

            ${MEDIA_QUERIES.belowDesktop} {
              display: block;
            }
          `}
        >
          <CustomSwiper
            loop
            id="grow-your-business"
            css={`
              .swiper-pagination {
                /* bottom: -50px; */
                display: none;
              }
              .grow-your-business-prev {
                left: 0;
                top: 32px;
              }
              .grow-your-business-next {
                right: 0;
                top: 32px;
              }
            `}
            slidesPerView={1}
            arrowSize={24}
            bulletSize={11}
            navigation={{
              nextEl: ".grow-your-business-next",
              prevEl: ".grow-your-business-prev",
            }}
          >
            {STATS.map(stat => (
              <SwiperSlide key={stat.title}>
                <Stat {...stat} />
              </SwiperSlide>
            ))}
          </CustomSwiper>
        </div>
        <CTA mt={50} />
      </div>
    </Container>
  )
}

function Stat({ title, description }) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div
        css={`
          margin-bottom: 42px;
          font-size: 60px;
          font-weight: bold;
          text-align: center;
          line-height: 0.45;
        `}
      >
        {title} <IconArrow />
      </div>
      <div
        css={`
          max-width: 20rem;

          ${MEDIA_QUERIES.belowDesktop} {
            max-width: 25rem;
          }
        `}
      >
        <P opacity={0.55}>{description}</P>
      </div>
    </div>
  )
}
