import React from "react"
import { SwiperSlide } from "swiper/react"
import CustomSwiper from "../common/CustomSwiper"
import Container from "../common/Container"
import { DEVICES, TESTIMONIALS } from "../../config"
import styled from "styled-components"

const Veil = styled.div`
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background 250ms ease;

  &:hover {
    background-color: rgba(31, 31, 31, 0.89);
  }

  @media (max-width: ${DEVICES.desktop}px) {
    background-color: rgba(31, 31, 31, 0.89);
  }
`

function Testimonial({ image, name, bio, testimony }) {
  return (
    <div
      css={`
        position: relative;
      `}
    >
      <img
        css={`
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          vertical-align: bottom;
        `}
        src={image}
        alt={name}
      />
      <Veil>
        <div
          css={`
            position: absolute;
            left: 50%;
            bottom: 5%;
            transform: translate(-50%, -5%);
            width: 100%;
            height: auto;
            text-align: center;
            padding: 0 24px;
          `}
        >
          <div
            css={`
              opacity: 0;
              transition: opacity 250ms ease;
              ${Veil}:hover & {
                opacity: 1;
              }

              @media (max-width: ${DEVICES.desktop}px) {
                opacity: 1;
              }

              @media (max-width: ${DEVICES.tablet}px) {
                max-width: 270px;
                margin: 0 auto;
              }
            `}
          >
            <div
              css={`
                font-size: 18px;
                line-height: 1.44;
                min-height: 250px;
                max-width: 277px;
                margin: 0 auto;

                @media (max-width: ${DEVICES.mobile}px) {
                  min-height: 180px;
                  max-width: 270px;
                  font-size: 16px;
                  line-height: 1.5;
                }
              `}
            >{`"${testimony}"`}</div>
            <div>—</div>
          </div>
          <div
            css={`
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 8px;
              margin-top: 12px;
            `}
          >
            {name}
          </div>
          <div
            css={`
              font-size: 14px;
              line-height: 1.29;
              max-width: 250px;
              margin: 0 auto;
            `}
          >
            {bio}
          </div>
        </div>
      </Veil>
    </div>
  )
}

function Testimonials() {
  return (
    <>
      <Container light>
        <Container
          compact
          css={`
            padding: 48px 0;
          `}
        >
          <h2
            css={`
              font-size: 32px;
              font-weight: bold;
              line-height: 1.5;
              text-align: center;
              margin: 0;

              @media (max-width: ${DEVICES.mobile}px) {
                font-size: 20px;
                line-height: 1.4;
              }
            `}
          >
            3000+ health professionals trust PNOĒ
          </h2>
        </Container>
      </Container>
      <Container>
        <CustomSwiper
          id="testimonial"
          spaceBetween={0}
          slidesPerView={1}
          breakpoints={{
            1025: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            nextEl: ".testimonial-next",
            prevEl: ".testimonial-prev",
          }}
          arrowSize={24}
          css={`
            .swiper-next,
            .swiper-prev {
              visibility: hidden;
            }

            @media (max-width: ${DEVICES.mobile}px) {
              .swiper-next,
              .swiper-prev {
                visibility: visible;
              }
            }
          `}
        >
          {TESTIMONIALS.map(testimonial => (
            <SwiperSlide key={testimonial.name}>
              <Testimonial
                image={testimonial.image}
                name={testimonial.name}
                bio={testimonial.bio}
                testimony={testimonial.testimony}
              />
            </SwiperSlide>
          ))}
        </CustomSwiper>
      </Container>
    </>
  )
}

export default Testimonials
