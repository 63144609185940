import React from "react"
import Container from "../common/Container"
import GetStarted from "./GetStarted"
import MobileSnapSlider from "./MobileSnapSlider"
import SnapSlider from "./SnapSlider"
import { DEVICES } from "../../config"
import useWindowSize from "../../hooks/useWindowSize"

function Slider() {
  const { width } = useWindowSize()

  return (
    <Container
      compact
      css={`
        padding: 108px 0;

        h2 {
          font-size: 32px;
          font-weight: bold;
          line-height: 1.5;
          text-align: center;
          margin: 0;
          margin-bottom: 8px;
        }

        p {
          opacity: 0.7;
          font-size: 24px;
          line-height: 1;
          text-align: center;
          font-weight: normal;
          margin-bottom: 56px;
          margin-top: 0;
        }

        @media (max-width: ${DEVICES.desktop}px) {
          padding: 64px 0;
        }

        @media (max-width: ${DEVICES.mobile}px) {
          h2 {
            font-size: 24px;
            line-height: 1.33;
          }

          p {
            font-size: 16px;
            line-height: 1.43;
          }
        }
      `}
    >
      <h2>The most advanced app for your clients</h2>
      <p>
        PNOĒ Precision houses your clients' activity, nutrition, and recovery
        program all in one app and guides them 24/7 based on their metabolism.
      </p>
      {width > DEVICES.desktop ? <SnapSlider /> : <MobileSnapSlider />}
      <div
        css={`
          margin-top: 56px;
          text-align: center;

          a {
            max-width: 35rem;
            padding: 8px 16px;
            text-align: center;
          }
        `}
      >
        <GetStarted />
        {/* <GetStarted.Tail boldFree={false} /> */}
      </div>
    </Container>
  )
}

export default Slider
