import React from "react"
import { SwiperSlide } from "swiper/react"
import CustomSwiper from "../common/CustomSwiper"
import Container from "../common/Container"
import { DEVICES, STATS } from "../../config"
import Logo from "../../assets/images/logo.inline.svg"
import useWindowSize from "../../hooks/useWindowSize"
import Button from "../common/Button"
import { Link } from "gatsby"

function StatIcon({ icon }) {
  return (
    <div
      css={`
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border-style: solid;
        border-width: 2px;
        border-color: #bf0049;
        margin-bottom: 21px;
        box-shadow: 5px 5px 10px 0 #ccc2c4, -5px -5px 10px 0 #ffffff;
        svg.auto-svg {
          width: 48px;
          height: 48px;
          fill: #434242;
        }
      `}
    >
      {icon}
    </div>
  )
}

function Stat({ icon, name, description }) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <div
        css={`
          font-size: 20px;
          font-weight: bold;
          line-height: 1.4;
          margin-bottom: 12px;
          text-align: center;
        `}
      >
        {name}
      </div>
      <StatIcon icon={icon} />
      <div
        css={`
          opacity: 0.55;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          text-align: center;
        `}
      >
        {description}
      </div>
    </div>
  )
}

function DetectionSlide({ icon, name, description }) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <StatIcon icon={icon} />
      <div
        css={`
          font-size: 20px;
          font-weight: bold;
          line-height: 1.4;
          text-align: center;
        `}
      >
        {name}
      </div>
      <div
        css={`
          font-size: 16px;
          line-height: 1.5;
          opacity: 0.55;
          text-align: center;
          max-width: 180px;
          margin-top: 10px;
        `}
      >
        {description}
      </div>
    </div>
  )
}

function Stats() {
  const { width } = useWindowSize()

  return (
    <Container
      light
      css={`
        padding: 82px 0;
        @media (max-width: ${DEVICES.tablet}px) {
          padding: 64px 0;
        }
      `}
    >
      <Container compact>
        <h2
          css={`
            font-size: 32px;
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 16px;
            max-width: 760px;
            @media (max-width: ${DEVICES.tablet}px) {
              font-size: 20px;
              line-height: 1.6;
            }
          `}
        >
          <Logo
            css={`
              fill: var(--text);
              height: 32px;
              width: auto;
              margin-right: 20px;
            `}
          />
          yields results
        </h2>
        <p
          css={`
            font-size: 20px;
            line-height: 1.4;
            margin-bottom: 76px;
            text-align: center;
          `}
        >
          After 3 months of PNOĒ based training users improved in all important
          areas
        </p>
        {width > DEVICES.tablet ? (
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
              gap: 50px;
            `}
          >
            {STATS.map(stat => (
              <Stat
                key={stat.name}
                icon={stat.icon}
                name={stat.name}
                description={stat.description}
              />
            ))}
          </div>
        ) : (
          <CustomSwiper
            loop
            id="detection"
            css={`
              .swiper-pagination {
                /* bottom: -50px; */
                display: none;
              }
              .detection-prev {
                left: 0;
                top: 32px;
              }
              .detection-next {
                right: 0;
                top: 32px;
              }
            `}
            slidesPerView={1}
            arrowSize={24}
            bulletSize={11}
            pagination={{ clickable: true, el: ".detection-pagination" }}
            navigation={{
              nextEl: ".detection-next",
              prevEl: ".detection-prev",
            }}
          >
            {STATS.map(stat => (
              <SwiperSlide key={stat.name}>
                <DetectionSlide
                  icon={stat.icon}
                  name={stat.name}
                  description={stat.description}
                />
              </SwiperSlide>
            ))}
          </CustomSwiper>
        )}
      </Container>
      <div
        css={`
          display: flex;
          justify-content: center;
        `}
      >
        <Button mt={82} as={Link} to="/the-science">
          Dive into the science
        </Button>
      </div>
    </Container>
  )
}

export default Stats
